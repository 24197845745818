var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(!_vm.isDataLoaded)?_c('b-card',[_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]):_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.sendNotification($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"match","name":_vm.$t('labels.match'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.matches')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"label","options":_vm.matches,"reduce":function (item) { return item.value; },"placeholder":_vm.$t('labels.match')},model:{value:(_vm.form.match),callback:function ($$v) {_vm.$set(_vm.form, "match", $$v)},expression:"form.match"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"title-input","name":_vm.$t('labels.title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.title'),"label-for":"title-input"}},[_c('b-form-input',{attrs:{"id":"title-input","placeholder":_vm.$t('messages.your_title_notification_here')},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"body-area","name":_vm.$t('labels.body'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.body'),"label-for":"body-area"}},[_c('b-form-textarea',{attrs:{"id":"body-area","rows":"4","placeholder":_vm.$t('messages.your_body_notification_here')},model:{value:(_vm.form.body),callback:function ($$v) {_vm.$set(_vm.form, "body", $$v)},expression:"form.body"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"type","name":_vm.$t('labels.send_to'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(_vm._s(_vm.$t('labels.send_to')))]),_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"options":_vm.types,"reduce":function (item) { return item.id; },"label":"name"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('LoadingButton'),_c('b-button',{attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.$t('labels.reset'))+" ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }