<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-primary my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>

        <b-form v-else
          novalidate
          @submit.prevent="sendNotification"
        >
          <b-row>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="match"
                :name="$t('labels.match')"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.matches')"
                >
                  <v-select
                    v-model="form.match"
                    :dir="$store.state.appConfig.layout.direction"
                    label="label"
                    :options="matches"
                    :reduce="item => item.value"
                    :placeholder="$t('labels.match')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                vid="title-input"
                :name="$t('labels.title')"
                rules="required"
              >
              <b-form-group
                :label="$t('labels.title')"
                label-for="title-input"
              >
                <b-form-input
                  id="title-input"
                  v-model="form.title"
                  :placeholder="$t('messages.your_title_notification_here')"
                />
                <small class="text-danger">{{ errors[0] }}</small>

              </b-form-group>
                </validation-provider>
            </b-col>

          </b-row>

          <b-row>
          <b-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              vid="body-area"
              :name="$t('labels.body')"
              rules="required"
            >
            <b-form-group
              :label="$t('labels.body')"
              label-for="body-area"
            >
              <b-form-textarea
                id="body-area"
                v-model="form.body"
                rows="4"
                :placeholder="$t('messages.your_body_notification_here')"
              />
              <small class="text-danger">{{ errors[0] }}</small>

            </b-form-group>
            </validation-provider>
          </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="mb-md-0 mb-2"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="type"
                :name="$t('labels.send_to')"
                rules="required"
              >
              <label>{{ $t('labels.send_to') }}</label>
              <v-select
                v-model="form.type"
                :dir="$store.state.appConfig.layout.direction"
                :options="types"
                :reduce="item => item.id"
                label="name"
                class="w-100"
              />
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>

            </b-col>

          </b-row>

          <b-row>
            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import formMixin from '@/mixins/formMixin'

export default {
  components: {
    flatPickr,
  },
  mixins: [formMixin],
  data() {
    return {
      matches: [],
      isDataLoaded:false,

      config: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
      },
      form: {
        title: null,
        body: null,
        match: null,
        type:null

      },
    }
  },

  created() {
    this.getLiveMatch()


  },
  computed: {
    types() {
      return [
        {
          id: 1,
          name: this.$t('labels.send_to_all_customers'),
        },
        {
          id: 0,
          name: this.$t('labels.notify_only_interested_customers_about_this_match_or_its_teams'),
        },
      ]
    },
  },
  methods: {

    getLiveMatch() {
      this.axios.get('/liveMatches')
        .then(res => {
          this.matches= res.data
        })
        .catch(() => {
          this.isDataLoaded = true
        })
        .finally(() => {
          this.isDataLoaded = true
        });
    },

    sendNotification() {
      let route = `/faster-notification/${this.form.match}`

      let $form = this.loadFormData()


      const formData = this.serializeForm($form)
      this.$store.dispatch('app/isLoading', true)
      this.$refs.form.validate()
        .then(result => {
          if (result) {
            this.axios.post(route, formData)
              .then(response => {
                this.$store.dispatch('app/isLoading', false)


                this.afterSubmitWithoutRedirect(response.data.message)
                setTimeout(() => {
                  // Reload the window after the wait
                  location.reload();
                }, 1000);


              })
              .catch(error => {
                this.$store.dispatch('app/isLoading', false)
                this.$bvToast.toast(error.response.data.message, {
                  title: this.$t('Error'),
                  variant: 'danger',
                  solid: true,
                  autoHideDelay: 5000,
                  appendToast: true,
                })
                this.$refs.form.setErrors(error.response.data.errors)
              })
              .finally(() => {
                this.$store.dispatch('app/isLoading', false)
              })
          } else {
            this.$store.dispatch('app/isLoading', false)
            this.formErrors = this.$refs.form.errors
            this.$bvToast.toast(this.$t('messages.please_fill_all_the_required_fields'), {
              title: this.$t('Error'),
              variant: 'danger',
              solid: true,
              autoHideDelay: 5000,
              appendToast: true,
            })
          }
        })
        .catch(() => {
          this.isDataLoaded = true
        })

        .finally(() => {
          this.$store.dispatch('app/isLoading', false)
          this.isDataLoaded = true

        })
    },



  },

}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
